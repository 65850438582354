﻿.track-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: 0;
  background-color: #333333;
}

.track-list li {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 0px;
  border-bottom: 0.25px solid #6e6e6e;
  transition: background-color 0.3s ease;
}

.track-list li:hover {
  background-color: #444444;
}

.track-list li input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.2);
}

.track-list li .track-name {
  font-size: 14px;
  font-weight: bold;
  color: white;
}

.track-list li:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.select-all {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #4d4c4c;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom: 0;
  margin-bottom: 0;
  font-size: 16px;
  cursor: pointer;
}


.select-all input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.2);
}

.select-all label {
  font-size: 14px;
  font-weight: bold;
  color: white;
}

.select-all:hover {
  background-color: #4a4a4a;
}

.profile-manager-wrapper {
  padding: $space-md;
  display: flex;
  flex-direction: column;

  .profile-manager-header {
    display: flex;

    button {
      margin-left: $space-lg;
    }
  }

  .profile-search {
    width: 30%;
    margin-left: 0;
    margin-bottom: 0;
    margin-right: auto;
  }

  .profile-tile {
    display: flex;
    flex-direction: column;
    background: rgb(34, 34, 34);
    padding: $space-md;
    border-radius: 5px;
    width: 15%;
    min-width: 15rem;

    .profile-tile-name {
      color: rgba($color-secondary, 0.5);
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $space-md;
    }

    div {
      color: rgba($color-secondary, 0.5);
      margin-left: auto;
      margin-right: auto;
    }

    button {
      width: 100%;
    }

    .profile-context-button {
      width: auto;
      margin-left: auto;
      background: none;
      color: rgba($color-secondary, 0.5);
      padding: 0;
    }
  }
}

.edit-profile-wrapper {
  height: 100%;
  overflow: hidden;

  #return {
    padding-left: $space-md;
    padding-top: $space-md;

    &:hover {
      background: unset;
    }
  }

  .profile-information {
    display: flex;
    align-items: center;
    padding-left: $space-sm;
    padding-right: $space-sm;

    h1 {
      margin-right: 3rem;
      margin-bottom: 0;
    }

    span,
    h3 {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 1rem;
    }

    input {
      margin: 0;
    }

    div:not(.custom-spinner, .custom-loader) {
      width: auto;
      margin: 0;
      padding-top: 0;
    }

    button.publish-button {
      margin-left: auto;
      min-width: 120px;
    }

    .profile-page-name {
      min-width: 400px;
      border-radius: 0px;
    }

    .profile-contextmenu-button {
      margin-right: 40px;
      border: 1px solid gray;
      background: transparent;
      color: rgba($color-secondary, 1);
    }
  }

  .publish-status-wrapper {
    padding-left: $space-sm;
    padding-right: $space-sm;
    padding-top: 5px;

    .publish-status {
      margin-bottom: 0;
      text-align: right;
      color: $grey-1;
      font-weight: bold;
    }
  }

  .tabs {
    margin-bottom: 0;
    padding-left: $space-md;

    ul {
      margin-bottom: 0;
    }

    button {
      color: rgba($color-secondary, 0.5);
    }

    .selected {
      button {
        color: $color-secondary;
        background-color: rgb(34, 34, 34);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .moods-tab,
  .assign-tab,
  .schedule-tab,
  .volume-tab {
    background: rgb(34, 34, 34);
    width: 100%;
  }
}

.profile-stats {
  color: $grey-1;

  .stats {
    margin-left: 0.5rem;
    color: $white;
  }
}

.publish-modal-container {
  #sub-header {
    color: $white;
  }

  .publish-tabs {
    .tab-header {
      margin-right: 0;

      .btn {
        padding-left: 30px;
        padding-right: 30px;
        color: $white;
        background-color: $grey-2;
      }

      &.selected {
        .btn {
          background-color: $color-primary;
          color: $black;
        }
      }

      &:first-child {
        .btn {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }

      &:last-child {
        .btn {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }

    margin-bottom: 0;
  }



  .publish-tab-content {
    margin-top: -10px;
    color: $white;

    .publish-datetime-input {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;

      input {
        margin: 0 10px;
        max-width: 150px;
      }
    }
  }

  .form-controls #cancel {
    box-shadow: $white 0px 0px 0px 2px inset;
    color: $white;

    &:hover {
      background-color: $grey-2;
    }
  }
}

.modal-message {
  margin-top: 2rem;
  margin-bottom: 0;
  font-size: 1.1rem;
}

.schedule-controller-container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;

  .schedule-selection-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-height: 50px;
    justify-content: space-between;

    .dropdown__container.single,
    .input-group {
      margin: 0;
      width: 250px !important;
    }

    .schedule-selection-btns-container {
      .save-button {
        margin-right: 2rem;
      }
    }
  }

  .schedule-table-instruction {
    margin: 1rem 0 0 1rem;
    font-weight: 700;
    font-size: 1.3rem;
  }

  // volume schedule
  .volume-schedule-time {
    display: flex;
    flex-direction: column;
    flex-grow: 24;
    padding: 2rem 2rem 2rem 0;
  }

  .volume-schedule-row {
    display: flex;
    flex-direction: row;
  }

  .label-column {
    color: white;
    min-width: 50px;
    max-width: 50px;
  }

  .volume-schedule-header-cell {
    border: 0.5px solid transparent;
    display: flex;
    flex-grow: 2;
    flex-basis: 0;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    max-height: 50px;
    min-width: 40px; // This determine how wide the header cell
    max-width: 40px; // This determine how wide the header cell

    &.time-header {
      border-left-color: white;
      border-right-color: white;
      min-width: 40px;
    }
  }

  .volume-schedule-cell {
    border: 0.5px solid white;
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    min-height: 50px; // This determine how high the time slot cell
    max-height: 50px;
    min-width: 20px; // This determine how wide the time slot cell
    max-width: 20px; // This determine how wide the time slot cell
    background-color: transparent;
    margin: 0;
    padding: 0;
    align-items: center;


    .time-slot-cell {
      height: 80%;
      width: 100%;
      min-width: 20px;
      display: flex;
      align-items: center;
      z-index: 2; // making it on top of the border line
      color: transparent;

      &:hover {
        background-color: white !important;
      }

      &.left-end-cell {
        margin-left: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &.right-end-cell {
        margin-right: 5px;
        min-width: 10px !important;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &.first-volume-schedule {
        color: black !important;
        font-size: small;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 6px;
    border: 0px solid $color-primary;
  }
}

// Profile Page / Edit Profile / Volume Tab 
.volume-tab-container {
  display: flex;
  min-height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
  flex-direction: row;
  overflow: auto;

  .volume-controller-container {
    display: flex;
    flex-direction: column;
    width: 250px;
    min-width: 200px;
    padding: 2rem;

    .label {
      font-weight: bold;
    }

    .volume-buttons-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .volume-button {
        height: 2rem;
        margin: 0.25rem 0.5rem;
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
        background-color: transparent;
        border: none;
        cursor: pointer;

        .volume-content {
          height: 100%;
          font-size: 1.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;

          &:hover {
            opacity: 1,
          }
        }
      }
    }
  }
}

label {
  display: block;
}

label>p {
  display: inline-block;
}

.profile-item {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disabled-disliked-switch {
  margin-left: 1rem;

  /* space between text and toggle */
  input {
    display: none;
  }

  .slider {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
    background-color: #ccc;
    border-radius: 34px;
    cursor: pointer;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: white;
    left: 3px;
    bottom: 3px;
    transition: .4s;
  }

  input:checked+.slider:before {
    transform: translateX(14px);
  }
}

input:checked+.slider {
  background-color: #eaba16;
}
﻿.playlisting-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100vh - 70px);
  overflow-x: hidden;
  
  &.admin {
    height: calc(100vh - 70px - 2.4rem);
  }

  p {
    margin: 0;
  }
  
  .see-all {
    padding: 0;

    &:hover {
      text-decoration: none;
    }
  }
  
  div.icon-top:before {
    margin-bottom: 0;
    cursor: pointer;
  }
}

.search-panel-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  margin: 1rem 0.5rem 1rem 1rem;
  background-color: $grey-8;
  animation: search-appear 0.5s forwards;
  min-width: 18vw;
  
  &.expanded {
    display: none;
  }
  
  &.uploads {
    animation: none;
  }
  
  &.start-open {
    animation: none;
  }
}

.search-placeholder {
  margin-top: 25vh;
  text-align: center;
  padding: 0 2rem;
  margin-left: auto;
  margin-right: auto;
  color: $grey-1;
  
  &.editor {
    margin-top: 26vh;
    padding-left: 4rem;
  }
}

.playlisting-container .search {
  padding: 1rem 1rem 0;
}

.search {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  
  .input-group {
    min-width: unset;
  }

  h4 {
    margin: 0;
  }
}

.search-term {
  width: 100%;
  background-color: $black;
  color: $white;
  border-radius: 5px;

  input {
    border-radius: 5px;
    border: 1px solid $black !important;

    &:hover {
      border: 1px solid $grey-10 !important;
    }
    
    &:focus {
      border: 1px solid $grey-4 !important;
    }
  }
  
  &.new {
    h4 {
      display: inline;
    }
  }
}

.playlist-tab .search-term {
  margin: 0;
}

// Very specific for 1 page
.playlist-tabs-container .playlist-tab-container .playlist-tab .search {
  margin-top: 1rem;

  .playlist-summary {
    margin-top: 1rem; 
  }
}

.results-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.results {
  overflow-y: scroll;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  padding: 1rem;
  
  .result-tracks {
    width: 100%;
    height: 95%;
    
    // &::-webkit-scrollbar {
    //   width: 7px;
    // }
    
    // &::-webkit-scrollbar-thumb {
    //   background-color: $color-primary;
    //   border-radius: 6px;
    //   border: 0px solid $color-primary;
    // }
  }

  p {
    font-size: 0.82rem;
  }

  
  h4 {
    color: $grey-1;
    margin-bottom: 0.5rem;
  }

  &::-webkit-scrollbar {
    width: 7px;
  }
  
&::-webkit-scrollbar-thumb {
  background-color: $color-primary;
  border-radius: 6px;
  border: 0px solid $color-primary;
}
}

.infinite-scroll-area {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  flex-wrap: nowrap;

}
.infinite-scroll-header {
  padding: 0 1rem;
}

.infinite-scroll-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.result-section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.result-section {
  margin-top: 1rem;
  .result-item {
    &.playlists, &.genres, &.artists, &.albums, &.primaryGenres {
      padding: 0.5rem 1rem;
      // TODO:

      &:hover {
        background-color: $grey-2;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}

.result-list {
  &::-webkit-scrollbar-thumb {
		background-color: rgb(238, 200, 69);
		border-radius: 6px;
		border: 0px solid rgb(238, 200, 69);
	}

  &::-webkit-scrollbar {
    width: 7px;
  }
}

.see-all-blurb {
  margin-top: 1rem !important;
  font-weight: bold;
  font-size: large;
  color: $grey-1;
  
  &.term {
    color: $white;
  }
}

.summary {
  font-size: small;
  color: $grey-1;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 5rem;
}

.result-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: $grey-8;
  border-bottom: 1px solid $grey-4;
  user-select: none;
  
  .icon-warning {
    flex: 1;
    margin-top: auto;
    margin-bottom: auto;
    color: $color-support-red;
    
    &:before {
      margin: 0;
    }
  }

  &.selected {
    background-color: $selected-track;
  }
  
  &.art, &.track {
    margin: 0;
  }
  
  &.beard-item {
    border-bottom: 1px solid $grey-1;
    margin-top: 0;
    background-color: $grey-2;
    cursor: pointer;
    padding: 0.5rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    
    &:hover {
      background-color: $grey-4;
    }
    
    p {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .name {
      font-weight: bold;
    }
    
    button {
      display: block;
      padding: 0;
      font-weight: lighter;
    }
  }
  
  &.no-result {
    color: $grey-1;
  }
}

.title {
  font-weight: bold;
  color: $white;
}

@mixin track-label($width-adjustment) {
  width: calc(100% - $width-adjustment);

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: $grey-1;
    user-select: none;
    margin: 0;
    
    &.title {
      font-weight: bold;
      color: $white;
    }
  }
  
  &.art {
    flex-direction: row;
    align-items: center;
    position: relative;
    display: flex;

    .play-button {
      display: none;
    }
    
    .pause-button {
      display: none;
    }
    
    .track-art-imgs {
      display: flex;
      height: 100%;
      
      &:hover {
        .album-art {
          display: none;
        }

        .play-button {
          display: inline;
          padding: 5px;
        }
        
        .pause-button {
          display: inline;
          padding: 5px;
        }
      }
    }
    
    img {
      width: 35px;
      height: 35px;
      user-select: none;
      border-radius: 4px;
      margin: auto 0.5rem auto 0;

      @include media(lgx) {
        width: calc(35px / 1.2);
        height: calc(35px / 1.2);
      }

      &:hover {
        cursor: pointer;

        .album-art {
          display: none;
        }

        .play-button {
          display: inline;
        }
        
        .pause-button {
          display: inline;
        }
      }
    }
    
    .text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: auto;
      color: $grey-1;
      user-select: none;
    }
  }
}

.track-label {
  @include track-label(6rem);
}

.track-label-lrg {
  @include track-label(50rem);

  p.ml {
    margin-left: 40px;
  }
}

.existing-track-row {
  margin-bottom: 1px;

  .input-group.input-group__checkbox {
    margin-right:10px;
    align-content: center;
  }

  .hidden {
    visibility: hidden;
  }
}

.track-details {
  text-align: right;
  color: $grey-1;
  min-width: 5rem;
  margin-left: auto;
  
  p {
    user-select: none;
    margin: 0;
  }
  
  &.art {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

.playlist-tabs-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  margin: 1rem 0.5rem;
  border-radius: 5px;
  animation: tabs-appear 0.5s forwards;
  overflow-y: hidden;
  overflow-x: hidden;

  min-width: 18vw;
  
  &.start-open {
    animation: none;
  }
  
  &.expanded {
    display: none;
  }

  .tabs {
    margin-bottom: 0;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    
    &::-webkit-scrollbar {
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 6px;
      border: 0px solid $color-primary;
    }

    ul {
      margin-top: 0;
      margin-bottom: 0;

      li {
        &:not(:last-child) {
          margin-right: $space-xs;
        }
      }
    }

    button {
      color: rgba($color-secondary, 0.5);
      
      &:hover {
        text-decoration: none;
      }

      .spacer {
        width: 2rem;
        content: "";
      }

      .close-tab::before {
        margin: 0;
      }
    }

    .selected {
      button {
        color: $color-secondary;
        background-color: $grey-8;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  
  .playlist-tab-container {
    height: calc(100% - 3rem);
    width: 100%;
  }
}

.playlist-tab {
  height: 100%;
  width: 100%;
  background-color: $grey-8;
  padding: 0;
  display: flex;
  flex-direction: column;
  border-radius: 0 6px 6px 6px;

  .bank-header-container {
    display: flex;
    padding: 1rem 1rem 0;

    div {
      cursor: pointer;
      margin: auto 0 auto auto;
    }
  }
  
  .search {
    padding-top: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .infiniteListEndButton{
    border: none;
    background: transparent;
    width: 100%;
    color: gray;
    margin: 10px 0;
  }
}

.playlist-editor-container {
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: 1rem;
  background-color: $grey-8;
  animation: contract-editor 0.5s forwards;
  overflow-y: hidden;

  &.start-open {
    animation: none;
  }
  
  &.expanded {
    width: 100%;
    animation: expand-editor 0.5s forwards;
    
    .search {
      width: 36.4%;
    }
  }
  
  .search {
    padding: 0;
  }
}

@keyframes expand-editor {
  0% { width: 48.9vw; }
  100% { width: 100%; }
}

@keyframes contract-editor {
  0% { width: 100% }
  100% { width: 48.9vw; }
}

// TODO: useless
@keyframes search-appear {
  0% { 
    width: 20vw;
  }
  
  100% {
    width: 20vw;  
  }
}

@keyframes tabs-appear {
  0% {
    width: 0;
  }
  
  100% {
    width: 25vw;
  }
}

.active-playlists-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 6px;
    border: 0px solid $color-primary;
  }
}

.playlist-editor-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  .header-button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
    h4 {
      margin-bottom: 0;
    }
    
    button {
      margin-left: 2rem;
      
      &:hover {
        box-shadow: none;
      }
    }
  }
}

.input-beard-wrapper {
  width: 100%;
}

.beard {
  position: absolute;
  top: 4rem;
  
  max-height: 400px;
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 6px;
    border: 3px solid $color-primary;
  }
  
  padding: 1rem 1.5rem;
  
  background-color: $grey-2;
  z-index: 10;

  .infinite-scroll-button-container {
    height: 2rem;
    
    .infinite-scroll-button {
      display: inline-flex;
    }
  }
}

.playlist-results-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

.playlist-tiles {
  flex: 1;
  overflow-y: auto;
  margin-right: 1rem;
  max-width: 50%;

  &.expanded {
    min-width: calc(15%);
  }

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 6px;
    border: 0px solid $color-primary;
  }
}

.tile-drop-area {
  width: 100%;
}

.playlist-tile {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  border: 1px solid $black;
  background-color: $black;
  padding: 1rem;
  margin-bottom: 1rem;
  
  p {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }
  
  &.active {
    border: 1px solid $color-primary;
  }
  
  &:hover {
    border: 1px solid $color-brand-03;
  }
  
  .icon-more-vertical {
    cursor: pointer;
  }
}

.playlist-contents {
  display: flex;
  flex-direction: column;
  border: 0.5px solid $grey-1;
  border-radius: 5px;
  overflow-y: hidden;
  flex: 1.7;
  
  &.expanded {
    min-width: calc(25%);
    max-width: calc(25%);
    margin-right: 1rem;
    
    .tooltip-container {
      max-width: 17rem;
    }
  }
}

.playlist-contents-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  width: 100%;
  
  padding: 1rem;

  border: 0.5px solid $grey-1;
  border-radius: 5px;
  
  .icon-top {
    cursor: pointer;
  }
}

.drop-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  height: 4rem;
  width: calc(100% - 2rem);
  
  margin: 1rem;
  padding: 1.5rem;

  color: $grey-1;

  background-image: repeating-linear-gradient(0deg, #a0a0a0, #a0a0a0 7px, transparent 7px, transparent 14px, #a0a0a0 14px), repeating-linear-gradient(90deg, #a0a0a0, #a0a0a0 7px, transparent 7px, transparent 14px, #a0a0a0 14px), repeating-linear-gradient(180deg, #a0a0a0, #a0a0a0 7px, transparent 7px, transparent 14px, #a0a0a0 14px), repeating-linear-gradient(270deg, #a0a0a0, #a0a0a0 7px, transparent 7px, transparent 14px, #a0a0a0 14px);
  background-size: 2px 100%, 100% 2px, 2px 100% , 100% 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  
  border-radius: 5px;

  &:hover {
    background-image: repeating-linear-gradient(0deg, #EEC845, #EEC845 7px, transparent 7px, transparent 14px, #EEC845 14px), repeating-linear-gradient(90deg, #EEC845, #EEC845 7px, transparent 7px, transparent 14px, #EEC845 14px), repeating-linear-gradient(180deg, #EEC845, #EEC845 7px, transparent 7px, transparent 14px, #EEC845 14px), repeating-linear-gradient(270deg, #EEC845, #EEC845 7px, transparent 7px, transparent 14px, #EEC845 14px);
    color: $color-primary;
  }
  
  .add-to-playlist {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    width: 100%;

    p {
      margin: 0;
    }
  }
}

.expand:hover {
  text-decoration: none;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.modal-container {
  z-index: 2;
}

.new-playlist-modal {
  background-color: $grey-4;
  flex-direction: column;
  justify-content: center;
  top: 19%;
  max-height: 65vh;
  width: 50%;
  margin: 0 auto;

  .new-playlist-form {
    height: 100%;
    
    .input-group {
      margin: 0;
    }
    
    input {
      background-color: $grey-8;
      border: 1px solid $grey-8;
      
      &:hover {
        border: 1px solid $grey-8;
      }
    }

    input[type="radio"] {
      appearance: none;
      border: convert-rem(2px) solid get-contrasting-text-color($background);
    }

    .import-radio {
      .input-group{
        margin: $space-xs;
      }
    }
    
    .search.selection {
      background: $grey-8 !important;
    }
    
    .separator {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
      
      h4 {
        margin-top: auto;
        margin-bottom: auto;
        padding-right: 1rem;
      }
    }

    .form-controls {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 1rem;

      .cancel {
        margin-right: 1rem;
      }
    }
  }
}

.delete-confirmation-modal + .edit-confirmation-modal{
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  .form-controls {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    
    .submit {
      margin-left: 1rem;
    }
  }
}

.search-filter {
  display: flex !important;
  max-width: 45px;
  max-height: 45px;
  margin-left: 1rem;
  
  &::before {
    margin: 0 !important;
  }
  
  &.has-filter-count {
    border: 2px solid $color-primary;

    &::before {
      // margin-left: 1.25rem !important;
      color: $color-primary;
    }
  }
}

.search-filter-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  h4 {
    margin: 0;
  }
  
  &.tab {
    .search-filter {

      &.has-filter-count {
        border: 2px solid $color-primary;

        &::before {
          // margin-left: 1.25rem !important;
          color: $color-primary;
        }
      }
    }
  }
}

a.ui.label {
  background-color: $grey-4 !important;
}

.vocal-options-label {
  color: $grey-1;
}

.vocal-options {
  margin-top: 1rem;
  margin-bottom: 1rem;
  button {
    margin-right: 1rem;
  }
}

.csv-download {
  margin-right: auto;
  max-width: 336px !important;
}

// .ui.multiple.search.dropdown>input.search {
//   padding: 0.6rem 0 0.6rem 0;
//   margin-left: 0;
// }

.sort-playlist {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.filter-count {
  background-color: $color-primary;
  
  font-size: small;
  
  border-radius: 50%;
  color: $black;
  position: absolute;
  top: -1rem;
  right: -1rem;
  height: 25px;
  min-height: 25px;
  width: 25px;
  min-width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.move-tracks-form-controls {
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .cancel {
    margin-right: 3px;
  }

  .move {
    margin-left: 3px;
  }

  
}

.form-controls {
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .cancel {
    margin-right: 1rem;
  }
}

.tooltip-container {
  width: 27rem;
  max-width: 27rem;
  visibility: hidden;
  background-color: $grey-8;
  opacity: 0;
  position: fixed;
  z-index: 3;
  overflow: hidden;
  padding: 0.25rem;
  
  .tooltip {
    font-weight: bold;
    color: $white;
    white-space: initial;
  }
}

p.title:hover + .tooltip-container {
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  transition-timing-function: linear;
}


///////////////////////////////////////////////
.tabs-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 6px;
    border: 0px solid $color-primary;
  }

  // @include media(lgx) {
  //   overflow-x: scroll;
  // }

  // &.expanded {
  //   display: none;
  // }
}

.tab-container {
  display: flex;
  flex-direction: column;
  width: 33%;
  min-width: 350px;
  border-radius: 5px;
  margin: 1rem 0.5rem 1rem;
  background-color: $grey-8;
}

.top-section {
  padding: 1rem 1rem 0;
}
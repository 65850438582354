/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Layout
-------------------------------- */
/* 
_layout.scss is the set up of your project on where things sit.
For example below, grid-template-areas are set in a way to position your navigation depending on 
the type of navigation you choose to use. If you want things positioning differently, change the grid-template-areas
*/

/* full body layout */
/* -------------------------------------------------*/
/* ----------Set your project layout here ----------*/
/* -------------------------------------------------*/


// % protected region % [Change project layout here] on begin
/*
This class is where your information is in, sits next to the nav-vertical or below the nav-horizontal.
Change if require a new layout for your body content or if you have added a new custom component
*/

.body-content {
	overflow-y: auto;
	overflow-x: hidden;
	grid-area: body;
	display: grid;
	grid-template-areas: "header" "content";
	grid-template-rows: auto 1fr;
	background-color: $background;
	color: get-contrasting-text-color($background);

	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $color-primary;
		border-radius: 6px;
		border: 0px solid $color-primary;
	}
}

.body-content.logged-in {
	height: calc(100vh - 70px);
}

.body-content.logged-in.body-admin {
	height: calc(100vh - 70px - 2rem);
}

.audio-player-container {
	position: absolute;
	left: 0;
	color: rgba($color-secondary, 0.5);
	display: flex;
	width: 100%;
	bottom: 0;
	height: 70px;
	background: $grey-10;
	z-index: 3;
	padding-left: $space-lg;
	padding-right: $space-lg;
}

.fade-modal {
	position: fixed;
	display: flex;
	flex-direction: row;

	color: rgba($color-secondary, 0.9);

	max-height: 140px;
	bottom: 70px;
	left: calc(50vw - 24rem);
	width: 48rem;

	z-index: 3;
}

$border-radius: 10px;

.fade-controls {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;

	&.column {
		flex-direction: column;
		align-items: flex-start;

		.fade-row {
			padding-left: 0;
			padding-right: 0;
			justify-content: space-between;

			.manual-fade {
				order: 1;
	
				padding-top: 1rem;
				max-height: 5.5rem;

				background: $grey-10;

				border-radius: 6px 0 6px 6px;
			}

			.show-fade-btn.open {
				background: $grey-10;

				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}

	.fade-row {
		display: flex;
		justify-content: center;
		align-items: center;
		column-gap: 1rem;
		padding: 0.5rem 1rem;
		flex-wrap: wrap;
		background: $grey-10;
		max-width: 100%;

		transition: all 0.05s ease;

		&.round-right {
			border-top-right-radius: $border-radius;
		}

		&.round-left {
			border-top-left-radius: $border-radius;
		}

		&:first-of-type {
			&.invert-border {
				&::before {
					box-shadow: none;
				}
			}
		}

		.show-fade-btn {
			width: 4.5rem;
			padding: 1rem 0;
		}

		.fade-label {
			font-size: 16px;
			font-weight: bold;
			width: 2rem;
		}

		.fade-play-btn {
			flex-wrap: nowrap;
			width: 2rem;
			height: 2rem;
			text-align: right;

			padding: 0 0.5rem;
		}

		.manual-fade {
			position: relative;
			display: flex;
			flex-basis: 100%;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			max-width: 100%;
			max-height: 4.5rem;

			gap: 0.5rem;
			padding-bottom: 1rem;

			transition: all 0.05s;

			opacity: 1;
			overflow-y: hidden;

			border-top-right-radius: $border-radius;

			&.hidden {
				max-height: 0;
				padding: 0;
				opacity: 0;

				pointer-events: none;

				border-top-right-radius: 0;
			}

			.input-group {
				min-width: 5rem;
				max-width: 5rem;
				margin: 0;
	
				input {
					margin: 0;
					font-size: convert-rem(16px);
					padding: 1rem 0.8rem;
					text-align: center;
				}
			}

			.save-btn {
				width: 5rem;
			}
		}

		.done-track-button {
			width: 5rem;
		}
	}
}

.invert-border:not(.column, .column .fade-row) {
	position: relative;

	&.invert-top {
		&::before, &::after {
			top: 0;
		}
	}

	&::before, &::after {
		content: "";
		position: absolute;
		
		background: none;
		bottom: 0;
		height: calc(4.5rem - 4px);
		width: $border-radius;
		box-shadow: 0 $border-radius 0 0 $grey-10;
	}

	&::before {
		left: -$border-radius;
		border-bottom-right-radius: $border-radius;
	}

	&::after {
		right: -$border-radius;
		border-bottom-left-radius: $border-radius;
	}
}

.collection-component,
.form-submission {
	padding: $space-md;
}


// % protected region % [Change project layout here] end

// % protected region % [Add additional colours here] on begin
.delete-account-page {
	background-color: $background;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100vw;
	color: white;
	padding: 50px;
	height: 100vh;

	.delete-form {
		display: flex;
		flex-direction: column;
	}

	.delete-form-label {
		margin: 0 0 10px 0;
		font-weight: bold;
		font-size: 1.1rem;
	}

	.delete-form-input {
		background: $grey-8;
		padding: 10px;
		border: 1px solid $grey-7;
		margin-bottom: 5px;
		border-radius: 5px;
		font-size: 1.1rem;
		color: white;
		width: 300px;
	}

	.delete-form-button {
		margin-top: 30px;
	}

	.delete-form-error {
		color: red;
	}
}
// % protected region % [Add additional colours here] end
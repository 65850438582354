﻿.venues-dashboard,
.clients-dashboard {
  height: calc(100vh - 100px);
  
  &.frontend-admin {
    height: calc(100vh - 70px - 2.4rem);

    .collection-component .collection__list table th.list__header--actions {
      background: $grey-10;
    }

    .collection-component .collection__list table td.list__items--actions {
      background: $black;
    }

    .collection-component .collection__list table tbody tr {
      border-bottom: none;
    }
  }

  .client-wrapper {
    padding: $space-md;
    
    .crud--VenueEntity .client-wrapper {
      padding: 0;
      
      a {
        display: none;
      }
      
      .client-venues-header {
        margin-top: 1rem;
      }
    }
    
    .collection-component {
      padding: 0;
    }
  }

  .crud--VenueEntity,
  .collection-component {
    height: inherit;
    max-width: 93vw;
    
    .collection__list {
      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-primary;
        border-radius: 6px;
        border: 0px solid $color-primary;
      }
    }
  }
  

  #return {
    padding-left: 0;
    padding-top: 0;
  }
  
  h1 {
    margin: 0;
  }
  
  .new-client-container {
    padding: $space-md;
    
    form {
      .agency {
        margin-left: 0;
        max-width: 25%;  
      }
      
      .clientName {
        input {
          max-width: 25%;
        }
      }
      
      .contact-details {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        
        .input-group {
          margin-right: 1rem;
          margin-left: 0;
        }

        .contactName {
          width: 10%;
        }

        .contactNumber {
          width: 10%;
        }
        
        .emailAddress {
          width: 25%;
        }
        
        button {
          height: 100%;
          align-self: center;
          
          &:before {
            margin: 0;
          }
        }
      }
      
      .add-contact {
        display: block;
        margin-bottom: 1rem;
      }
      
      .cancel-button {
        margin-right: 1rem;
        border-bottom: none;
      }
    }
  }
  
  .client-container {
    
    p {
      color: $grey-1;
      margin-bottom: 0;
    }
    
    .client-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      
      h1 {
        margin: 0;
      }
      
      button {
        padding-top: calc(2 * 1rem);
      }
    }
    
    .client-contacts {
      .main-client-contact {
        .detail-container {
          display: flex;
          flex-direction: row;
          
          .detail-label {
            width: 175px;
          }
        }
      }
    }
    
    .client-venues {
      .client-venues-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 2rem;
        
        h4 {
          margin-right: 2rem;
          margin-bottom: 0;
        }
      }
      
      .venue-container {
        border: 1px solid $grey-2;
        border-radius: 5px;
        padding: calc(2 * 1rem);
        display: flex;
        flex-direction: column;
        margin-bottom: calc(2 * 1rem);
        
        .venue-info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          
          .venue-contact-info {
            display: flex;
            flex-direction: row;
            
            .venue-header {
              margin-bottom: calc(2 * 1rem);
              
              button {
                padding: 0;
                
                &:hover {
                  text-decoration: none;
                  background: none;
                }
              }

              h4 {
                margin-bottom: 0.5rem;
                margin-top: 0;
              }
              h5 {
                margin-top: 0;
              }
            }
            
            .venue-photo {
              max-width: 137px;
              max-height: 121px;
              border-radius: 5px;
              
              margin-right: 2rem;
            }
          }
        }
        
        .venue-contacts {
          display: flex;
          flex-direction: row;
          margin-bottom: 2rem;
          
          .venue-contact-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-left: 3rem;

            &:first-child {
              margin-left: 0;
            }
          }
        }
        
        .venue-links {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          a.venue-url-link {
            width: fit-content;
            margin-bottom: 0.5rem;
            text-decoration: underline;
            display: inline-block;

            &:hover {
              border-bottom: none;
            }
          }
        }
      }
      
      .zones-container-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        margin-top: calc(2 * 1rem);
        margin-bottom: 1rem;
        
        h4 { margin-bottom: 0; }

        .zone-progress-bar-wrapper {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          margin-left: 1rem;

          p {
            font-weight: bold;
            margin-right: 1rem;
            margin-bottom: 0;
            text-align: end;
          }

          .zone-progress-bar {
            height: 1rem;
            margin-bottom: 0.3rem;
            width: 20rem;
            background-color: $grey-4;
            border-radius: 5px;

            .zone-progress {
              height: 1rem;
              background-color: $white;
              border-radius: 5px;
            }
          }
        }
      }
      
      .zones-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        
        .zone-wrapper {
          min-width: 255px;
          max-width: 255px;
          
          border: 1px $grey-2 solid;
          border-radius: 5px;
          
          padding: 1rem;
          margin-right: calc(1 * 1rem);
          margin-bottom: 1rem;
          
          h5 {
            margin-bottom: 0.5rem;
            width: 100%;
            @include truncate;
            white-space: nowrap;
          }
          
          p {
            width: 100%;
            @include truncate;
            white-space: nowrap;
          }
          
          button {
            padding-left: 0;
            font-size: small;
          }
        }
      }
      
      .add-zone {
        margin-top: calc(1 * 1rem);
      }
    }
  }
  
  section.collection-component {
    .collection__menu {
      .search {
        width: 50%;
        .input-group {
          input {
            width: 100%;
          }
        }
      }
      
      &.flattened {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.venue-modal-container {
  form > .upload {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .input-group--tooltip > .tooltip > .tooltip__content {
      left: 12rem;
      min-width: 9rem;
    }
    
    .logo-preview {
      margin-left: 2rem;
      width: 100px;
      height: 100px;
    }

    .logo-preview-na {
      margin-left: 2rem;
      border: 1px $grey-0 dashed;
      border-radius: 5px;
      width: 100px;
      height: 100px;
      background-color: rgb(25, 25, 25);
    }
  }
}

.zone-modal-container {
  form > .zone-wrapper > .upload {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .input-group--tooltip > .tooltip > .tooltip__content {
      left: 12rem;
      min-width: 9rem;
    }
    
    .logo-preview {
      margin-left: 2rem;
      width: 140px;
      height: 100px;
    }

    .logo-preview-na {
      margin-left: 2rem;
      border: 1px $grey-0 dashed;
      border-radius: 5px;
      width: 140px;
      height: 100px;
      background-color: rgb(25, 25, 25);
    }
  }
}

.zone-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.zone-tile-wrapper {
  min-width: 20rem;
  max-width: 20rem;

  border: 1px $grey-2 solid;
  border-radius: 5px;

  margin-right: calc(1 * 1rem);
  margin-bottom: 1rem;

  h5 {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    width: 100%;
    @include truncate;
    white-space: nowrap;
  }

  p {
    margin-left: 1rem;
    max-width: 19rem;
    @include truncate;
    white-space: nowrap;
  }

  button {
    margin-left: -0.1rem;
    // margin-left: 1rem;
    font-size: small;
  }
}

.header-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  border-top-right-radius: 5px;
  width: calc(20rem - 2px);
  height: 5rem;
}

.active-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  
  .zone-connected {
    margin-left: 1rem;
    padding: 0.35rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: $color-support-green;
    content: ' ';
  }
  
  .zone-inactive {
    margin-left: 1rem;
    padding: 0.35rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: $grey-1;
    content: ' ';
  }

  .zone-disconnected {
    margin-left: 1rem;
    padding: 0.35rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: $color-support-red;
    content: ' ';
  }
  
  p {
    margin-left: 1rem;
    margin-bottom: 0;
    max-width: 20rem;
  }
}

.zone-inputs-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.zone-modal-container {
  .switch {
    margin-bottom: 1rem;
  }
}

.contact-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    margin-bottom: 0;
  }
  
  .contact-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.main-contact-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;

  p {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}

.productKey-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .productKey {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    width: 360px;
    
    margin: 0;
    margin-right: 2rem;
    
    input {
      width: 100%;
    }
  }
  
  .refresh-key-button {
    margin-top: auto;
    margin-bottom: 1rem;
  }

  .copy-button {
    margin-left: 1rem;
    margin-top: auto;
    margin-bottom: 1rem;
    
    &:before {
      margin: 0;
    }
  }
}

.add-zone-button {
  align-self: flex-start;
}

.fade-settings {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 100%;
  min-height: 85px;
  height: 100%;
  flex-wrap: wrap;

  .input-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: 1rem;
    margin-left: 0;
    width: 100%;
    min-height: 100%;
    
    input {
      min-width: 13rem;
      max-width: 15rem;
      min-height: 3rem;
      margin-bottom: 1rem;
      margin-top: 0;
      width: 100%;
    }
  }
}

.switch-control-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  div {
    width: 100%;
    flex: 1;
  }
}


.publish-zone-container {
  margin: 0 0 1rem 1rem;

  .sync-status-label {
    color: $grey-1;
    font-size: 1rem;
    margin: 0;
  }

  .sync-status-content{
    margin-bottom: 0.5rem;
    margin-left: 0;
    color: white !important;
    // text-wrap:wrap;

  } 
  .update-required-label {
    margin-left: 1.25rem;
  }

  .sync-status-label-container{
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .publish-button{
    margin-left: -1rem;
  }

  .sync-status {
    margin-right: 0.5rem;
    padding: 0.35rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    content: ' ';

    &.successful {
      background-color: $color-support-green;
    }

    &.warning {
      background-color: $color-support-yellow;
    }
    &.error {
      background-color: $color-support-red;
    }
  }
}

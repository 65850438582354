﻿.application-user-edit-container {
  width: calc(100vw - 5.5rem);
  height: 100%;
  padding: 3rem 15rem;
  
  .back-button {
    margin-left: -1rem;
  }
  
  .application-user-edit-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .user-name {
    margin-top: 1rem;
  }
  
  .user-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;

    .input-group {
      min-width: 40rem;
      margin: 0;
    }
    
    .double-input {
      display: flex;
      flex-direction: row;
      align-items: center;

      .input-group {
        min-width: 19.5rem;
        margin-right: 1rem;
      }
    }
  }
  
  .separator {
    height: 1px;
    width: 100%;
    border: 1px solid $white;
    margin: 2rem 0;
  }
  
  .management-access-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    row-gap: 1rem;
    
    .control-section-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      // align-items: center;
      // border: 1px solid pink;

      .venue-select {
        flex: 1;
        margin: 1rem 2rem 1rem 0;
        padding: 0;
        
        .single {
          min-height: 47.42px;
        }

        .dropdown {
          height: 100%;
          margin: 0;
          padding: 0;
        }
      }
      
      .zone-select-wrapper, .new-zone-select-wrapper {
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .zone-select {
          margin: 0;
          padding: 0;
          width: 100%;
          
          .multiple {
            min-height: 47.42px;
            max-height: none;
            width: 100%;

            .search {
              margin: 0;
              padding: 0;
              padding-top: 0.4rem;
            }
          }

          .dropdown {
            margin-bottom: 1rem
          }

          &.error {
            .multiple {
              border-color: $color-support-red;
            }
          }
        }

        .access-error {
          color: $color-support-red;
          margin-bottom: 0;
          margin-top: -0.5rem;
        }
      }
      

      .delete-button {
        margin: auto 0 auto 0.5rem;
      }
      
      .delete-button:before {
        margin: 0;
      }
    }
    
    .management-access-controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      margin-top: 1rem;
      
      .submit-button {
        margin-top: 4rem;
      }
    }
  }
}
.schedule-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 4rem;
}

.schedule-selection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: 45px;

  .mood-instruction {
    width: 10rem;
    min-width: 10rem;
    font-weight: bold;
    margin-right: 4rem;
  }

  .input-group {
    margin-left: 2rem;
  }

  .dropdown {
    width: 30rem;
  }

  .ui.disabled.search.selection.dropdown.dropdown__container.single {
    opacity: 1;
    max-height: 45px;

    div.text {
      color: $white;
    }
  }
}

.schedule-builder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 95%;

  .schedule-instruction {
    margin-left: 1.5rem;
    margin-top: 1rem;
  }

  .schedule-mood-tiles-container{
    min-width: 200px;
  width: fit-content;
  max-width: 300px;
  margin-right: 1rem;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 6px;
    border: 0px solid $color-primary;
  }
  }
}

.moods {
  margin: 1rem 0;
  min-width: 170px;
  width: 100%;
  max-width: 180px;
  margin-right: 1rem;

  scrollbar-color: $color-primary rgb(128, 128, 128, 0.2);

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 6px;
    border: 0px solid $color-primary;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
  }

  .mood-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    
    &.selected {
      padding: 0.35rem;
    }
    
    .mood-tile {
      width: 8rem;
      margin: 0;
      min-height: 45px;
      white-space: break-spaces;
      word-wrap: break-word;
      word-break: break-word;

      p {
        font-weight: bold;
      }

      &.silence {
        color: $white;
      }
    }
  }
}

.schedule {
  width: 80vw;
  height: 100%;
  overflow: auto;
  padding: 1rem 0;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 6px;
    border: 0px solid $color-primary;
  }

  .day {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 !important;

    p {
      margin: 0;
    }

    .day-label {
      width: 4rem;
      height: 4rem;
      min-width: 4rem;
      padding: 1rem;
    }

    .timeSlot {
      min-width: 0.75rem;
      height: 4rem;
      border: 1px solid $white;
      cursor: pointer;

      position: relative;

      display: flex;
      align-items: center;
      overflow: visible;

      color: $black;
      padding: $space-md 0.75rem;

      &.silence {
        color: $white !important;
      }

      &.left {
        .filled-slot {
          background-position-x: -0.3rem;
          margin-left: 0.25rem;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          z-index: 2;
          overflow: visible;
        }

        &.right {
          .filled-slot {
            width: 0.75rem;
            overflow: hidden;

            p {
              @include truncate;
            }
          }
        }
      }

      &.right {
        .filled-slot {
          width: calc(100% - 0.25rem);
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }

      @for $i from 1 through 9 {
        &.w-#{$i} {
          .filled-slot {
            p {
              min-width: calc(1.5rem * $i);
              max-width: calc(1.5rem * $i);
            }
          }
        }
      }

      &.w-max {
        .filled-slot {
          p {
            min-width: calc(1.5rem * 10);
            max-width: calc(1.5rem * 10);
          }
        }
      }
    }

    .time-label {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 1.5rem;
      max-width: 1.5rem;
      border-left: 1px solid $white;
      border-right: 1px solid $white;
      padding: 1.3rem calc(1.5rem + 1px);
    }
  }

  .top-left-corner {
    width: 2rem;
    height: 4rem;
  }
}

.filled-slot {
  position: absolute;
  display: flex;
  align-items: center;
  overflow: visible;
  max-height: 1.5rem;

  width: calc(100% + 2px);
  margin-left: -1px;
  left: 0;

  border: none;
  padding: 1.5rem 0.25rem;

  p {
    font-weight: bold;
    @include truncate;
    white-space: nowrap;
    padding-left: 0 !important;
    padding-right: 0 !important;
    pointer-events: none;
  }
}

$new-color-list: (
  a: #ff90ae,
  b: #ffa381,
  c: #ffc077,
  d: #ffe074,
  e: #f6ff98,
  f: #e4ff9b,
  g: #aeffa8,
  h: #b5fff7,
  i: #95f2cb,
  j: #adffc6,
  k: #c6fff7,
  l: #9bdfff,
  m: #84c2ff,
  n: #a2aaff,
  o: #c6d1ff,
  p: #c3a3ff,
  q: #e7bfff,
  r: #ffbaf1,
  s: #ff86cc,
  silence: $black,
);

@each $color, $color-var in $new-color-list {
  .timeSlot.#{$color} {
    .filled-slot {
      background: $color-var;
    }
  }

  @each $color-stripe, $colour-stripe-var in $new-color-list {
    .timeSlot.#{$color}-#{$color-stripe} {
      .filled-slot {
        background: repeating-linear-gradient(
          60deg,
          $colour-stripe-var,
          $colour-stripe-var 6px,
          $color-var 0,
          $color-var 20px
        );
      }
    }
  }

  .mood-wrapper.selected.#{$color} {
    border: 2px solid $color-var;
    border-radius: 10px;
  }
}

$hover-color-list: (
  hover-a: #ff90ae,
  hover-b: #ffa381,
  hover-c: #ffc077,
  hover-d: #ffe074,
  hover-e: #f6ff98,
  hover-f: #e4ff9b,
  hover-g: #aeffa8,
  hover-h: #b5fff7,
  hover-i: #95f2cb,
  hover-j: #adffc6,
  hover-k: #c6fff7,
  hover-l: #9bdfff,
  hover-m: #84c2ff,
  hover-n: #a2aaff,
  hover-o: #c6d1ff,
  hover-p: #c3a3ff,
  hover-q: #e7bfff,
  hover-r: #ffbaf1,
  hover-s: #ff86cc,
  hover-hover: $black,
);

@each $color, $color-var in $hover-color-list {
  .timeSlot.#{$color} {
    &.first {
      .filled-slot {
        &:hover {
          background-color: $color-var;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          margin-left: 0.5rem;
        }
      }
    }

    &.last {
      .filled-slot {
        &:hover {
          background-color: $color-var;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          margin-right: 0.5rem;
        }
      }
    }

    .filled-slot {
      &:hover {
        background-color: $color-var;
      }
    }

    &.selected {
      background-color: $color-var;
    }
  }

  .timeSlot.hover-hover.selected {
    background-color: $grey-1;
  }
}

.schedule-modal-Container {
  .form-controls {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      &:first-of-type {
        margin-right: 1rem;
      }
    }
  }
}

.discard-button {
  margin-left: 2rem;
  margin-right: 2rem;
}

.save-schedule-button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.schedule-tab-container {
  width: 100%;
  padding: 2rem;
  height: 70vh;
  min-height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
  overflow-y: hidden;
}

.schedule-actions {
  display: flex;
  .new-schedule {
    margin-left: 1rem;
  }
  .export-schedule {
    margin-right: 1rem;
  }
}

@media (max-width: 1280px) {
  .schedule-tab {
    .schedule-tab-container {
      .schedule-menu {
        .schedule-selection {
          p.mood-instruction {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.bg-color-print {
  background-color: #222222;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.flex-space-bt {
  justify-content: space-between;
}

.moods-list-export {
  // background-color: blanchedalmond;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  // grid-template-columns: 100%;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  justify-content: end;
  /* extra styles */
  grid-gap: 2px;
  padding-right: 15%;
  align-self: end;
}

.display-none {
  display: none;
}

.font-black {
  color: black;
}

.no-overflow {
  overflow: unset;
}

.h-auto {
  height: auto;
}

.pillList {
  border: none;
  width: 10px;
  height: 8px;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 16px;
  margin-right: 5px;
  margin-left: 10px;
  padding: 5px;
}

.title-export {
  margin: 0px;
  margin-left: 20px;
}



.buttons-container{
  display: flex;
}

.buttons-container-md{
  display: none;
}

.button-schedule {
  max-width: 45px;
  max-height: 45px;
}

.button-span {
  display: block;
}

.borders-transparent {
  border: 1px solid rgba(255, 255, 255, 0.01) !important;
}


@media (max-width: 1340px) {
  .schedule-actions {
    .new-schedule {
      margin-left: 1rem;
    }
    .export-schedule {
      margin-right: 1rem;
    }
  }

  .button-span {
    display: none;
  }

  .buttons-container-md{
    display: flex;
  }

  .buttons-container{
    display: none;
  }
}
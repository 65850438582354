﻿.mood-tab-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: scroll;
  width: 100%;
  padding: $space-md;
  min-height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);

  .mood-settings-container {
    width: fit-content;
    min-width: 180px;
    margin: 1rem;

    .switch {
      margin-bottom: 2rem;
    }

    .total-number,
    .prevent {
      margin-top: 2rem;
    }

    .total-number,
    .feedback-summary,
    .prevent {
      color: $grey-1;
    }
  }

  .mood-setting-container {
    display: flex;
    width: 100%;
    overflow-x: auto;
  }

  .playlists-container {
    width: 100%;
    min-width: 300px;
    margin-right: 1rem;
    background-color: $grey-6;
    border-radius: 6px;

    .search {
      padding-right: 1rem;
    }

    .add-to-mood {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      width: 100%;

      p {
        margin-bottom: 0;
      }
    }

    .playlists-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    div>.search {
      padding-left: 1rem;
    }

    div>.results {
      margin-top: 0;
      padding: 0;

      .result-section {
        height: 95%;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-primary;
          border-radius: 6px;
          border: 0px solid $color-primary;
        }
      }

      .result-item {
        margin: 0;
        padding: 1rem;
        background-color: $grey-6;

        &:hover {
          background-color: $grey-4;
        }

        &.mood {
          color: $color-primary;
          font-weight: bold;
          cursor: pointer;
        }

        .included {
          margin-right: 1rem;
        }

        .hightlighted-name {
          display: flex;
          align-items: center;
          width: 100%;

          p {
            width: 100%;
          }

          &:after {
            text-align: right;

            .icon-right {
              margin-left: auto;
            }
          }
        }

        p {
          margin: 0;
        }

        div.icon-top:before {
          margin: 0;
        }

        &.selected {
          background-color: $black;
        }
      }
    }
  }

  .playlist-contents-container {
    width: 100%;
    min-width: 300px;
    margin-right: 1rem;

    .playlist-tab {
      background-color: $grey-6;
      border-radius: 6px;
      overflow-x: hidden;

      .search {
        margin-top: 1rem;

        p {
          margin-top: 1rem;
          margin-bottom: 0;
        }
      }

      .result-item {
        background-color: $grey-6;

        .track-label {
          p.title {
            max-width: 20rem;
          }
        }

        &.selected {
          background-color: $selected-track;

          .included {
            background-color: $selected-track;

            &.true {
              background-color: $color-primary;
            }
          }
        }

        .included {
          content: ' ';
          width: 0.5rem;
          height: 0.5rem;
          margin: auto 0.5rem;
          border-radius: 50%;
          background-color: $grey-6;

          &.true {
            border-radius: 50%;
            background-color: $color-primary;
          }
        }

        input {
          margin-right: 1rem;
          margin-top: 1rem;
        }
      }
    }
  }
}

.mood-tiles-container {
  min-width: 100px;
  width: fit-content;
  max-width: 300px;
  margin-right: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 6px;
    border: 0px solid $color-primary;
  }
}

.mood-tab-header {
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;

  button {
    height: 45px;
    width: 45px;
  }

  h4 {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: $space-xl;
  }

  button.icon-top:before {
    margin-bottom: 0;
  }
}

.top-left-corner {
  padding: $space-md;
}

.mood-tile {
  display: flex;
  width: 8rem;
  padding-left: 1rem;
  padding-right: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: $space-md;
  margin-bottom: $space-sm;
  border-radius: 5px;
  color: $black;
  align-items: center;
  //max-height: 45px;
  font-weight: $text-bold;

  h5 {
    margin-bottom: auto;
    margin-top: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .mood-context-button {
    width: auto;
    margin-left: auto;
    background: none;
    color: rgba($color-secondary, 0.5);
    padding: 0;
    height: 100%;
    color: $black;
  }
}

.mood-modal-Container {
  .form-controls {
    display: flex;

    #cancel {
      margin-left: auto;
      margin-right: $space-md;
    }
  }
}

.mood-modal-row {
  display: grid;
  align-items: flex-start;
  gap: 1rem;

  .icon-information {
    &::after {
      // applies only to icon and not the text inside
      font-size: 1rem;
      color: $grey-1;
    }
  }

  &.top-row {
    grid-template-columns: 1fr auto;

    .row-element {
      width: 100%;
      margin: $space-sm auto;

      p {
        margin-bottom: 0;
        color: $grey-1;
      }

      .switch {
        margin: $space-xs 0;
      }
    }
  }

  &.row {
    display: flex;
    flex-direction: row;

    .row-element {
      width: 100%;

      p {
        margin-bottom: 0;
        color: $grey-1;
      }

      .switch {
        margin: $space-xs 0;
      }
    }
  }
}

.edit-mood-modal-Container {
  .mood-colours {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .colour-selector-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.3rem;
      margin: 0.5rem;
      border: 4px solid $grey-10;
      border-radius: 5px;

      .colour-selector {
        width: 8rem;
        height: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        color: $black;

        p {
          margin: 0;
          font-weight: bold;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

$new-color-list: (
  a: #ff90ae,
  b: #ffa381,
  c: #ffc077,
  d: #ffe074,
  e: #F6FF98,
  f: #e4ff9b,
  g: #aeffa8,
  h: #b5fff7,
  i: #95f2cb,
  j: #adffc6,
  k: #c6fff7,
  l: #9bdfff,
  m: #84c2ff,
  n: #a2aaff,
  o: #c6d1ff,
  p: #c3a3ff,
  q: #e7bfff,
  r: #ffbaf1,
  s: #ff86cc,
  silence: $black,
);

@each $color, $color-var in $new-color-list {
  .mood-tile.#{$color} {
    background-color: $color-var;
  }

  .colour-selector-wrapper.#{$color} {
    &.selected {
      border: 4px solid $color-var;
    }

    .colour-selector {
      background-color: $color-var;
    }
  }
}

.included {
  content: ' ';
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: $color-primary;
}

.select-all-checkbox-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 10rem;

  p {
    margin: 0;
  }

  margin-bottom: 0.5rem;
  margin-left: -0.5rem;

  .select-all-checkbox {
    margin-right: 1rem;
    margin-bottom: 0;
  }
}

.playlists-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
  padding-left: 1rem;

  h4 {
    margin-bottom: 0.5rem;
  }

  .actions-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .icon-more-vertical {
      margin-left: 1rem;
      margin-right: 1rem;

      &:before {
        margin-bottom: 0;
      }

      &:hover {
        cursor: pointer;
        color: $color-primary;
      }
    }

    .icon-order:before {
      margin: 0;
    }

    .icon-order:hover {
      cursor: pointer;
      color: $color-primary;
    }
  }
}

@media (max-width: 1000px) {
  .mood-tab-container {
    width: 100vw;
  }
}

@media (max-width: 1280px) {
  .mood-tab-container {

    .mood-tiles-container {
      .mood-tab-header {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 10rem;
        margin-top: 1rem;

        h4 {
          margin-bottom: 1rem;
        }
      }

      .mood-wrapper {
        width: 10rem;
        border-radius: 5px;

        .mood-tile {
          width: 8rem;
          padding: 0.5rem 1rem;
        }
      }
    }

    .playlists-container {
      // max-width: 20rem;
      border-radius: 6px;

      .playlists-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      // div.results {
      //   div.result-section {
      //     max-height: 39.8rem;
      //   }
      // }
    }
  }
}

.feedback-details-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .feedback-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .feedback-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 0.75rem;

      &.liked {
        padding-bottom: 7px;
      }

      &.disliked {
        padding-top: 8px;
      }

      &.icon-right {
        display: flex;
        flex-direction: row;

        &:after {
          color: $color-support-yellow;

          margin: 0;
          margin-left: 0.4rem;
          padding-top: 0.2rem;
        }
      }
    }
  }
}


.infiniteListEndButton {
  border: none;
  background: transparent;
  width: 100%;
  color: gray;
  margin: 10px 0;
}
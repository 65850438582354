/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Custom bot input styling
-------------------------------- */

// % protected region % [update input if required] on begin
/*This will enable dropdown fields requiring validation to manifest the same border red color as regular errored inputs*/
.input-group--error {
    .dropdown__container {
        .dropdown__control {
            border-color: $color-support-red;
        }
    }
}

/*Styling of date input */

//applying a white background to all date picker types and a hover effect
.input-group__date {
    .enabled {
        background-color: transparent;
        color: get-contrasting-text-color($background);
    }

    .disabled {
        background-color: rgba($grey-6, 0.4);
        border: none;
        color: get-contrasting-text-color($grey-6);

        &:hover {
            background-color: rgba($grey-6, 0.4);
            border: none;
            color: get-contrasting-text-color($grey-6);
        }
    }
}

&.flatpickr-calendar {
    font-family: $body-font-family !important;
    font-weight: $text-regular !important;
    border-radius: 6px !important;
    overflow: hidden;
    
    &:before {
        display: none;
    }

    &.arrowTop:after, &.arrowBottom:after {
        display: none;
    }

    .flatpickr-months .flatpickr-month {
        background-color: $grey-6 !important;
        border-radius: 0 !important;
    }

    .flatpickr-current-month .flatpickr-monthDropdown-months {
        background-color: transparent !important;
        
        option {
            color: $white;
            background-color: $grey-6;
        }
    }

    .flatpickr-months {

        .flatpickr-prev-month,
        .flatpickr-next-month {

            &:hover,
            &:active,
            &:focus {
                color: $color-primary !important;

                svg {
                    fill: $color-primary !important;
                }
            }

            svg {

                &:hover,
                &:active,
                &:focus {
                    fill: $color-primary !important;
                }
            }
        }
    }

    .numInputWrapper {

        .arrowUp,
        .arrowDown {
            border: none !important;
        }
        
        input[type='number'] {
            width: inherit;
        }
    }

    //Inner container
    .flatpickr-innerContainer {
        background: transparent;
        .flatpickr-rContainer {

            .flatpickr-weekdays,
            .flatpickr-weekday {
                background: $grey-6 !important;
                color: get-contrasting-text-color($grey-6);
            }
        }
    }

    .flatpickr-days {
        border: none !important;

        .dayContainer {
            background: $grey-8 !important;

            * {
                color: get-contrasting-text-color($black);
            }
        }

        .flatpickr-day {
            border: none !important;
            border-radius: 0 !important;
            font-weight: $text-regular !important;

            &:hover {
                background-color: $grey-4 !important;
                border-radius: 0 !important;
                @include transform-timing(.25s);
            }

            &:active {
                border: 1px solid $color-primary;
            }

            &:focus {
                border: 1px solid $color-primary;
            }

            &.selected {
                border-radius: 0 !important;
                background-color: $color-primary !important;
                color: get-contrasting-text-color($color-primary);
            }

            &.today {
                font-weight: $text-bold !important;
            }

            &.today:not(.selected) {
                color: $color-primary;
                border: none !important;

                &:after {
                    content: '';
                    width: 3px;
                    height: 3px;
                    background-color: $color-primary;
                }
            }

            &.inRange {
                background-color: $grey-3;
                box-shadow: -5px 0 0 $grey-3, 5px 0 0 $grey-3 !important;
            }

            &.endRange {
                box-shadow: none !important;
                background: $color-primary;
                color: get-contrasting-text-color($color-primary);
            }
        }
    }

    .flatpickr-calendar.hasTime .flatpickr-time {
        border: none !important;
        border-radius: 0 !important;
    }

    .flatpickr-time {
        background-color: $grey-6 !important;
        border: none !important;
        border-radius: 0 !important;

        * {
            color: get-contrasting-text-color($grey-6);
        }

        .numInputWrapper {
            width: max-content;

            .arrowUp,
            .arrowDown {
                display: none !important;
            }

            input[type="number"] {
                margin-top: 0 !important;
                width: 100%;
                border: none !important;

                &:hover {
                    color: $color-secondary;
                }

                &.flatpickr-hour {
                    display: flex;
                    margin-left: auto;
                    margin-right: $space-xxs;
                }

                &.flatpickr-minute {
                    display: flex;
                    margin-right: auto;
                    margin-left: $space-xxs;
                    margin-top: 6px;
                }
            }

        }

        .flatpickr-am-pm {
            &:hover,
            &:active,
            &:focus {
                background-color: $grey-8;
                color: get-contrasting-text-color($grey-8);
            }
        }
    }
}


/* End of datepicker styles */
// % protected region % [update input if required] end

﻿.reports-page-wrapper {
  padding: $space-md;
}

.report-options {
  width: 400px;
}

.report-date-range-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  .input-group {
    margin: 0;
  }
  
  .range-separator {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Collection - Menu Actions
-------------------------------- */
/*
Top section of collection - Search bar, filter buttons, grid buttons
*/

// % protected region % [Change collection menu action styles here] on begin
.collection__menu {
	display: flex;
	margin: $space-xs auto;
	align-items: center;

	.input-group {
		width: 100%;
		max-width: unset;
		margin: .5rem 0;

		input {
			&:not(:hover):not(:active):not(:focus) {
				border-color: transparent;
			}

			background-color: $grey-10;
		}

		label {
			font-weight: $text-regular;
		}

		.icon-look {
			position: absolute;
			right: 0;
			bottom: 1.2rem;

			&:hover {
				text-decoration: none;
				color: $color-secondary;
				@include transform-timing($animation-time);
			}

			&:before {
				margin-right: 0;
			}
		}
	}

	.btn-group {
		margin-left: 2rem;
		align-items: center;
	}

	//Action buttons
	.collection__actions {
		display: flex;
		width: 100%;

		.collection-list {
			display: flex;
			justify-content: flex-end;
			margin-left: auto;
		}

		.btn {
			width: auto;
			min-width: 45px;
			max-height: 45px;
			min-height: unset;
			padding: 1rem;
			margin-left: $space-xxs;
			align-items: center;
			
			&.icon-filter {
				background-color: $grey-2;
				font-size: $text-sm;
				max-width: 45px;
				padding: 0;
				color: get-contrasting-text-color($grey-10);
				text-align: center;
			}

			&:before {
				margin: .5rem auto;
				color: $color-primary;
			}

			&.icon-import {
				display: flex;
				padding: $space-xs $space-lg;
				min-height: $space-xxl;
			}

			&.icon-export {
				display: flex;
				padding: $space-xs $space-lg;
				margin-right: $space-xxs;
				min-height: $space-xxl;
			}

			&.icon-create {
				@include lightning-icon(after, plus);
				display: flex;
			}
			
			&:nth-child(2) {
				margin-left: auto;
			}

		}

	}

}

/******* Collection filter ********/
.collection__filters {
	border: convert-rem(2px) solid get-contrasting-text-color($background);
	border-radius: 6px;
	padding: $space-sm;
	margin-bottom: $space-md;
	min-height: convert-rem(200px);
	max-height: convert-rem(3000px);
	opacity: 1;
	@include cubic-bezier-ease-in (min-height, $animation-time);

	&.hide {
		@include cubic-bezier-ease-in (min-height, $animation-time);
		opacity: 0;
		height: 0;
		min-height: 0;
		max-height: 0;
		padding: 0;
	}

	.collection-filter-form__container {
		display: flex;
		flex-wrap: wrap;

		.input-group {
			margin: 0 $space-sm;
		}
	}

	.collection-filter-form__actions {
		display: flex;
		justify-content: flex-end;
	}
}

/***** Action menu, shows once user selects item in list *****/
.collection__select-options {
	background-color: $grey-10;
	display: flex;
	align-items: center;
	padding: $space-xs $space-md;
	margin-bottom: $space-lg;

	* {
		color: get-contrasting-text-color($grey-8);
	}

	.collection__selection-actions {
		display: flex;
	}

	.btn {
		padding: 0rem;
		background-color: transparent;
		color: get-contrasting-text-color($background);
		text-decoration: underline;
		margin-left: auto;
		font-weight: $text-regular;
		width: unset;
		line-height: 1.6;
		border: none;

		&:hover {
			text-shadow: convert-rem(0px) convert-rem(0px) convert-rem(1px) get-contrasting-text-color($background);
			@include transform-timing($animation-time);

			&:before {
				text-decoration: none;
			}
		}

		&:before {
			margin-right: $space-sm;
		}

		&:last-of-type {
			margin-right: 0;
			width: auto;
		}

		&.icon-export {
			&:before {
				transform: rotate(90deg);
			}
		}
	}

	.btn-group {
		margin-right: auto;
	}

	p {
		padding: 0rem;
		margin: 0rem;
		color: get-contrasting-text-color($grey-8);
	}

	.crud__selection-count {
		margin-left: auto;
	}

	min-height: convert-rem(50px);
	@include cubic-bezier-ease-in ($animation-time);

	&.hide {
		min-height: 0;
		margin-bottom: 0;
		@include cubic-bezier-ease-in ($animation-time);
		padding: 0;
		opacity: 0;
	}
}

// % protected region % [Change collection menu action styles here] end

// % protected region % [Add additional collection menu action styles here] off begin
// % protected region % [Add additional collection menu action styles here] end
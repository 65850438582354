﻿.assign-container {
  width: 100%;
  padding: $space-md;
  height: 43.3rem;
  max-height: 43.3rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.assigned-schedule {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;
  margin-bottom: auto;
  
  .input-group {
    margin: 0 0.5rem auto 0;
    
    .dropdown__container {
      margin-bottom: 0 !important;
    }
    
    .dropdown {
      margin-bottom: 0;
    }
    
    .disabled {
      div.text {
        color: $white;
      }
      
      i.dropdown {
        display: none;
      }
    }
  }
  
  button {
    margin-right: 0.5rem;
    margin-top: auto;
  }
  
  .icon-bin-delete.icon-top {
    &:before {
      margin: 1rem 0 0 0;
      padding: 0;
    }
  }
}

@media (max-width: 1280px) {
  .assign-container {
    width: 100%;
    padding: $space-md;
    height: 31rem;
    max-height: 31rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .assigned-schedule {
    .input-group {
      margin: 0.5rem 1rem auto 0;
    }
  }
}

.unassign-schedule {
  margin-bottom: 1rem;
}

.injectable-schedule {
  margin-top: 2.5rem;
}
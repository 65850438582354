@import "existing-track-panel";
@import "bank-track-details-panel";

.file-preview {
  margin: auto;
}

.uploads-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100vh - 70px);
  width: calc(100vw - 5.45rem);

  &.admin {
    height: calc(100vh - 70px - 2.4rem);
  }
  
  .bank-track-details-container,
  .playlist-tab {
    border-radius: 5px;
  }
}

.uploads-container .playlist-editor-container {
  margin: 0;
  height: 100%;
}

.search-panel-container.uploads {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: calc(100% - 2rem);
  border-radius: 5px;
  background-color: $grey-8;
  padding: 0;
  max-width: 33.33%;

  @include media(lgx) {
    min-width: 22rem;
  }

  .search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: unset;
    width: 100%;
    padding: 1rem;

    h4 {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    &.editor {
      padding-right: 0;
      height: 10%;

      .search-term {
        position: absolute;
        top: 1rem;
      }
    }

    .search-header {
      display: flex;
      width: 100%;
      
      h4 {
        margin: 0;
        white-space: nowrap;
      }
      
      button {
        margin-left: auto;
        display: flex;
        padding-top: 0;
        padding-bottom: 0;
        min-height: 45px;
      }
    }
  }
  
  .track-container {
    width: 100%;
    overflow-y: auto;
    flex-wrap: nowrap;
    height: auto;

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 6px;
      border: 0px solid $color-primary;
    }
    
    .bank-detail-container {
      width: 100%;
      border-bottom: 1px solid $grey-4;
      padding: 0.5rem 1rem;
      display: flex;

      &.active,
      &:hover {
        background: #121212;
        cursor: pointer;
      }

      .bank-name {
        font-weight: bold;
      }
      
      .bank-details {
        min-width: 80%;
        flex-direction: column;
        
        .bank-uploader {
          color: $grey-1;
        }
      }

      .input-group {
        display: flex;
        margin: unset;
        width: 100%;
        min-width: unset;
      }

      @mixin icon($color) {
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;

        &:before {
          color: $color;
        }
      }

      .icon-upload {
        &.green {
          @include icon($color-support-green);
        }
        &.red {
          @include icon($color-support-red-dark);
        }

        // Override tooltip position
        .tooltip__content {
          right: 4rem;
        }
      }

      .icon-warning {
        @include icon($color-support-red);
      }
    }
  }
}

.bank-modal-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  
  .csv-label {
    height: 100%;
    margin: auto auto auto 1rem;
  }
  
  button {
    max-width: 250px;
  }
  
  .no-existing {
    font-size: $text-md;
    margin-top: 1rem;
    color: $white;
  }
  .form-controls {
    button {
      margin-left: auto;
    }
    .custom-spinner {
      margin-left: 2rem;
      margin-right: 2rem;
      
      .custom-loader {
        margin-top: 1rem;
      }
    }
  }
}

.uploads-container {
  .bank-management-group {
    display: flex;
    flex-direction: row;
    flex: 3;
    overflow-x: auto;

    @include media(lgx) {
      overflow-x: scroll;
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 6px;
      border: 0px solid $color-primary;
    }
  }

  .playlist-tab {
    height: calc(100% - 2rem);
    border-radius: 5px;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 1rem 0.5rem;
    max-width: 33.33%;

    @include media(lgx) {
      min-width: 22rem;
    }

    .search{
      padding: 1rem;
    }
  }
}

.search-panel-container.uploads {
  margin: 1rem 0.5rem 1rem 1rem;
  flex: 1;
}

.uploads-container .playlist-editor-container {
  margin: 0;
  height: 100%;
  border-radius: 0;
  flex: 2;
}

.done-track-button {
  margin-right: 1rem;
}

.playlistContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $grey-8;
  margin: 1rem 0.5rem;
  overflow-y: hidden;
  width: 100%;
  max-width: 33.3%;
  min-width: 300px;
  border-radius: 6px;

  .playlistHeader {

    padding: 1.1rem;

    .bankHeaderContainer{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      
      .icon-more-vertical {
        background-color: transparent;
        color: white;
        border: 0
      }
    }
    
    .search-filter-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      
      &.tab {
        .search-filter {
          
          &.has-filter-count {
            border: 2px solid $color-primary;
            
            &::before {
              margin-left: 1.25rem !important;
              color: $color-primary;
            }
          }
        }
      }
    }
  }
  
}
  .infiniteListContainer {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    flex: 1;
    
    
    &::-webkit-scrollbar {
      width: 7px;
    }
    
  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 6px;
    border: 0px solid $color-primary;
  }

  .infiniteListEndButton {
    border: 0;
    background: transparent;
    color: gray;
    margin: 10px 0;
    width: 100%;
  }
}
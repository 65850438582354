/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Modal
-------------------------------- */
/*
This is the container for modals, which places them above the other content and applies a light overlay to the content
*/



// % protected region % [Change modal styles here] off begin
.modal-container {
	background-color: rgba($admin-white, 0.75);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.75);
}

/*The container for the modal content proper*/
.modal-content {
	position: relative;
	top: 30%;
	border: convert-rem(1px) solid $admin-grey-4;
	background: $admin-white;
	overflow: auto;
	border-radius: $btn-radius;
	outline: none;
	padding: $admin-space-md;
	height: fit-content;
	max-height: convert-rem(500px);
	overflow: auto;
	width: 50%;
	margin: 0 auto;
	z-index: $admin-z-index-modal;

	@include breakpoint(sm) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: convert-rem(500px);
		margin: auto;
		right: 0;
		bottom: 0;
	}

}

.modal {

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.btn {
			padding: 0;
		}
	}

	.input-group__textarea {

		textarea {
			width: 100%;
			max-width: unset;
		}
	}

	&__actions {
		display: flex;
		justify-content: flex-end;
		margin-top: $admin-space-md;

		.btn:not(:last-child) {
			margin-right: $admin-space-md;
		}
	}
}

// % protected region % [Change modal styles here] end
// % protected region % [Add additional modal styles here] off begin
// % protected region % [Add additional modal styles here] end
﻿@use "sass:math";

.audio-player {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;

  .details-controls-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 70px;

    .song-details-container {
      display: flex;
      flex: 1;

      @media only screen and (max-width: 1440px) {
        flex: 1.5;
      }

      .song-details {
        margin-top: auto;
        margin-bottom: auto;

        .track-label {
          width: 100%;
        }
      }
    }
  }
  
  .audio-controls-container {
    display: flex;
    flex: 5;

    .show-fade-button{
      min-width: 9rem;
      padding: 0.5rem 1rem;
      margin-left: 2rem;
      margin-right: 2rem;
    }
    
    span {
      margin-top: auto;
      margin-bottom: auto;
    }

    .audio-controls {
      flex: 1;
    }

    .track-length,
    .track-progress {
      padding: $space-xs;
      font-size: math.div($base-font-size, 1.5);
    }

    .track-length {
      margin-right: $space-md;
    }

    .progress {
      flex: 4;
    }

    .volume {
      margin-left: $space-xs;
      flex: 2;
    }
  }

  .icon-loudspeaker {
    margin: auto;

    &:before {
      margin-bottom: 0;
    }
  }
  
  .waveform-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;

    wave {
      overflow: hidden !important;
    }
  }
}

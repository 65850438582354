/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Expanded navigation
-------------------------------- */
/*
Expanded navigation is the vertical navigation that expands displaying titles next to icons.
*/

// % protected region % [Change expanded navigation styles here] on begin
.nav.nav--vertical {

	&.nav--collapsed,
	&.nav--expanded {
		top: 0;
		position: relative;
		z-index: 1;
		overflow: visible;

		//mobile responsive
		@include media(xs, sm, md) {
			position: absolute;
		}

		>ul {
			background-color: transparent;

			>li {
				position: relative;
				color: get-contrasting-text-color($grey-1);

				a {
					display: flex;
					border-bottom: none;
				}

				//mobile responsive
				@include media(xs, sm, md) {
					position: inherit;
				}
			}
		}

		a {
			&:before {
				margin: 0 $space-xs;
			}
		}

		//Expanding arrow at the botome
		>.nav__expand-icon {
			grid-area: expand;
			padding: $space-md $space-xs;
			cursor: pointer;
			color: $color-primary;
			background-color: transparent;
			font-size: 1.3rem;
			border: none;

			@include media(xs, sm, md) {
				margin: $space-xl $space-xxs;
				padding: 0;
			}

			&:before {
				margin: 0 $space-xs;
			}

			&:hover {
				color: $hover-state;
				border-bottom: none;
				@include transform-timing($quick-animation-time);
			}

			&:active,
			&:focus {
				color: $color-primary;
				border-bottom: none;
				@include transform-timing($quick-animation-time);
			}
		}
	}

	/***** When nav is expanded ******/

	&.nav--expanded {
		>ul >li a {
			width: fit-content;
		}


		a {
			span {
				width: 100%;
				opacity: 1;
				margin-right: $space-xxs;
				@include cubic-bezier-ease-in(width, $quick-animation-time);
			}
		}

		//Expanding arrow at the botome
		>a {
			&:before {
				content: '""';
				font-family: Arial;
				font-weight: bold;
			}

			display: flex;
			justify-content: space-between;

			&:after {
				content: '\e9d4' !important;
				font-family: 'lightning-icons';
				color: $grey-1;
				display: inline-block !important;
			}
		}
	}

	/****** Collapsed *****/

	&.nav--collapsed {

		//mobile responsive
		@include media(xs, sm, md) {
			align-items: center;
			justify-content: center;
			pointer-events: none;

			>ul {
				padding-top: 0;
			}
		}

		a {
			span {
				width: 0;
				opacity: 0;
				overflow: hidden;
				@include cubic-bezier-ease-in(width, $quick-animation-time);
			}
		}

		.expand-icon {
			pointer-events: auto;
		}
	}

}

// % protected region % [Change expanded navigation styles here] end

// % protected region % [Add additional expanded navigation styles here] off begin
// % protected region % [Add additional expanded navigation styles here] end
﻿.tab-group {
  display: flex;
  flex-direction: row;
  flex: 3;
  overflow-x: auto;

  @include media(lgx) {
    overflow-x: scroll;
  }

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 6px;
    border: 0px solid $color-primary;
  }

  &.expanded {
    display: none;
  }
}

.bank-track-details-container {
  background-color: $grey-8;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem 0.5rem;
  height: calc(100% - 2rem);
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  min-width: 300px;

  @include media(lgx) {
    min-width: 22rem;
  }

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 6px;
    border: 0px solid $color-primary;
  }

  .bank-track-details-header {
    display: flex;
    flex-direction: column;
    
    .track-label {
      display: flex;
      width: 100%;
      
      .icon-more-vertical {
        margin-left: auto;
        &:hover {
          cursor: pointer;
        }
      }
    }
    
    .track-details {
      width: 100%;
      padding-bottom: 1rem;
      border-bottom: 1px solid $grey-4;
    }
  }

  .fades-panel {
    .fade-row-header {
      max-width: 100%;
      display: flex;
      margin-top: 1rem;
      
      p {
        color: $grey-1;
      }

      .icon-warning {
        color: $color-support-red;
        margin: auto auto auto 1rem;
      }
    }
    
    .fade-row {
      background: inherit;
    }

    padding-bottom: 1rem;
    border-bottom: 1px solid $grey-4;
  }
  
  .tags-panel {
    p {
      color: $grey-1;
      margin: 0;
      padding-top: 1rem;
    }
    
    .section-container {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      
      .tags-badge {
        background: $grey-4;
        padding: 0.5rem;
        border-radius: 5px;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
      }

      .input-group__dropdown.input-group.input-group-block {
        margin: 0.5rem 0 0 0;

        .dropdown.dropdown__container {
          margin: 0;
        }
      }
    }

	.explicit-container {
		.switch {
			margin-top: 0.5rem;
		}
	}
  }
}

@media (max-width: 1280px) {
  .bank-track-details-container {
    .fades-panel {
      .fade-row {
        .fade-group {
          .input-group {
            input {
              width: 2.5rem;
            }
          }
        }
      }
    }
  }
}
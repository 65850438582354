/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Radio buttons
-------------------------------- */
/*
Custom styles for radio buttons
*/

/*consists of multiple input-group__radio in one div*/
// % protected region % [Change input group wrapper styles here] on begin
.input-group-wrapper__radio {
	//give with 100% so it doesn't look weird with other input groups
	width: 100%;

	&-header {
		width: 100%;
	}
}

// % protected region % [Change input group wrapper styles here] end

// % protected region % [Change input radio wrapper styles here] off begin
.input-group__radio {
	position: relative;
	margin: $space-sm 0;
	/*This customises the grid areas for checkboxes. By default, the grid area has been set up to automatically place a label
beside a tooltip and above an input. For checkboxes, the grid areas are re-arranged to allow these items to appear inline*/
	grid-template-columns: max-content max-content max-content;
	grid-template-areas: "input label tooltip" "validation validation validation";

	&-header {
		font-weight: $text-bold;
	}

	.input-group {
		margin: $space-xs;
	}

	label {
		position: relative;
		cursor: default;
		grid-area: label;
		margin-left: $space-sm;
	}

	p {
		grid-area: tooltip;
	}

}


/*Radio Groups direction/
.radio-group--vertical {
  flex-direction: column;
}

.radio-group--horizontal {
  flex-direction: row;
}

// % protected region % [Change input radio wrapper styles here] end


/*Radio Buttons Styling*/
// % protected region % [Change radio input styles here] on begin
input[type="radio"] {
	appearance: none;
	width: 18px;
	height: 18px;
	border: convert-rem(2px) solid get-contrasting-text-color($background);
	margin: 0;
	border-radius: 50%;
	cursor: pointer;
	//ovewrite
	padding: 0;
	grid-area: input;

	&:hover {
		color: $grey-6;
		background-color: $grey-10;
		border-color: $grey-3;
		@include transform-timing($animation-time);
	}

	&:active,
	&:focus {
		border-color: $color-secondary;
		color: $color-secondary;
		background-color: $grey-2;
		outline: none;
	}

	&:checked {
		outline: none;

		&:after {
			content: "";
			background-color: $color-primary;
			border-radius: 50%;
			width: 84%;
			height: 78%;
		}
	}


	&:focus {
		outline: none;
	}
}

// % protected region % [Change radio input styles here] end

// % protected region % [Change readonly radio input styles here] off begin
input[type="radio"][readonly] {
	background-color: $grey-0;
	pointer-events: none;
	border: convert-rem(3px) solid $grey-4;

	&:hover,
	&:focus,
	&:active {
		cursor: auto;
		outline: none;
	}

	&:after {
		display: none;
	}
}
// % protected region % [Change readonly radio input styles here] end

// % protected region % [Change readonly and disabled radio input styles here] off begin
input[type="radio"][readonly]+label,
input[type="radio"][disabled]+label {
	background-color: transparent;

	&:hover,
	&:focus,
	&:active {
		border: none;
	}
}
// % protected region % [Change readonly and disabled radio input styles here] end

// % protected region % [Change disabled radio input styles here] off begin
input[type="radio"][disabled] {

	background-color: $grey-2;
	border: convert-rem(3px) solid $grey-4;

	&:hover,
	&:focus,
	&:active {
		outline: none;
	}
}
// % protected region % [Change disabled radio input styles here] end

// % protected region % [Add additional radio styles here] off begin
// % protected region % [Add additional radio styles here] end
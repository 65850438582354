/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Navigation
-------------------------------- */
/*
Styling for basic navigation, vertical navigation and horizontal navigation.
*/

/****** General navigation styling shared amongst all navigation *****/
// % protected region % [Change generic navigation styles here] on begin
.nav {

	ul {
		width: 100%;
		margin-bottom: 0;

		.nav__category_button{
			color: $grey-1;
			display: flex;
			justify-content: space-between;
			width: 100%;
		}

		li {
			display: flex;
			flex-direction: column;
			list-style: none;
			position: relative;
			color: white;
			font-size: 18px;
			line-height: 1;

			//This is for sublinks
			&.nav__parent-link--active {
				border-left: 5px solid $color-primary;

				a {
					color: get-contrasting-text-color($background);
				}
			}

			a {
				color: white;
				text-decoration: none;
				padding: $space-xs;
				display: block;
				cursor: pointer;
				transition: none;
				border-bottom: none;
				font-weight: lighter;
				font-size: 1rem;
			}

			span {
				white-space: nowrap;
			}

			&:hover,
			&:focus,
			&:active,
			&.active {
				background-color: $grey-10;
				@include transform-timing($animation-time);
				
				a {
					color: get-contrasting-text-color($background);
					@include transform-timing($animation-time);

					&:before{
						color: $color-primary;
					}
				}

				//This is for sublinks
				ul li a {
					color: get-contrasting-text-color($color-primary);
					transition: none;
				}
			}

			@import 'sublinks-navigation.scss';
		}
	}
}

// % protected region % [Change generic navigation styles here] end

/****** Horizontal navigation (usually placed left) *****/
/****** Note that if you are working on the expanded navigation ie the a tag to expand the nav - go to expanded-navigation.scss ******/
// % protected region % [Change vertical navigation styles here] on begin
.nav--vertical {
	background-color: $background;
	overflow: auto;
	display: grid;
	grid-template-areas: 'expand''clients''libraryManagement''musicCuration''reporting''logout';
	grid-template-rows: min-content min-content min-content min-content auto 160px;
	grid-template-columns: 1fr;
	left: 0;
	grid-area: navvertical;
	height: 100%;
	box-shadow: convert-rem(-2px) convert-rem(4px) convert-rem(7px) convert-rem(-1px);

	@include media(md) {
		grid-template-areas: 'clients''libraryManagement''musicCuration''reporting''logout''expand';
		grid-template-rows: min-content min-content min-content min-content auto 120px 120px;
	}

	>ul {
		margin: 0;
		padding: 0;

		&:nth-of-type(1) {
			grid-area: clients;
		}

		&:nth-of-type(2) {
			grid-area: libraryManagement;
		}

		&:nth-of-type(3) {
			grid-area: musicCuration;
		}

		&:nth-of-type(4){
			grid-area: reporting;
		}

		&:nth-of-type(5) {
			grid-area: logout;
		}

		>li {
			display: block;

			&:hover,
			&:active,
			&:focus,
			&.active {
				color: get-contrasting-text-color($grey-1);
			}

			//position of sublinks for vertical nav
			.nav__sublinks {
				left: 100%;
				top: 0;
				max-width: convert-rem(0px);
				width: 0;
				max-height: 70vh;
				overflow: auto;
				@include cubic-bezier-ease-in(max-width, $animation-time);

				li {
					border-bottom: convert-rem(1px) solid get-contrasting-text-color($grey-1);
				}
			}

			.nav__sublinks--visible {
				max-width: convert-rem(9999px);
				width: auto;
				min-width: convert-rem(120px);

				span {
					width: 100%;
				}
			}
		}
	}

}

// % protected region % [Change vertical navigation styles here] end

/****** Horizontal navigation (usually placed top) *****/
// % protected region % [Change horizontal navigation styles here] on begin
// % protected region % [Change horizontal navigation styles here] end

/****** Tabs *****/
// % protected region % [Change tabs styles here] off begin
.tabs {
	margin-bottom: $space-xl;
	font-size: $text-md;

	ul {
		padding-left: unset;
		display: flex;
		flex-direction: row;
		list-style: none;

		li {
			cursor: pointer;

			&.disabled {
				cursor: not-allowed;
			}

			&:not(:last-child) {
				margin-right: $space-md;
			}

			a {
				color: get-contrasting-text-color($grey-1);
				text-decoration: none;
			}
		}
	}

	.active {
		@include text-shadow($color-primary);

		a {
			border-bottom: convert-rem(2px) solid;
		}
	}
}
// % protected region % [Change tabs styles here] end

// % protected region % [Change breadcrumbs styles here] off begin
/****** Breadcrumbs *****/
.breadcrumbs {
	padding-left: unset;
	display: flex;
	flex-direction: row;
	list-style: none;
	align-items: center;

	li {
		&.disabled {
			cursor: not-allowed;
		}

		&:not(:last-child) {
			margin-right: $space-sm;
		}

		&:before {
			vertical-align: middle;
		}

		a {
			color: get-contrasting-text-color($background);
			text-decoration: none;
			cursor: pointer;
		}
	}
}
// % protected region % [Change breadcrumbs styles here] end

/************ MOBILE NAVIGATION ***********/
// % protected region % [Change mobile navigation styles here] on begin
@include media(xs, sm, md) {

	//Horizontal mobile responsive
	.nav--horizontal {
		z-index: 2;

		ul li {
			padding: $space-xs;
			font-size: $text-md;
		}

		>a {
			grid-area: collapse;
			text-align: center;
			padding: $space-xxs;
			width: 100%;
			z-index: 1;
			margin: 0;
			font-size: $text-lg;
			visibility: visible;

			&:before {
				margin-right: 0 !important;
			}

			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
				border: none;
			}
		}
	}

	.nav--horizontal.nav--expanded {
		display: grid;
		grid-template-areas: 'collapse''ul';
		grid-template-rows: auto 1fr;
		grid-template-columns: 1fr;
		position: absolute;
		bottom: 0;

		ul:nth-of-type(2) {
			grid-area: ul;
			display: flex;
			flex-wrap: wrap;

			// min-height: 40vh;
			max-height: convert-rem(3000px);
			height: auto;
			overflow: hidden;
			@include heightScaleIn(2s);

			li {
				width: 50%;
				border-right: none;
				border-bottom: convert-rem(4px) solid $grey-0;

				&:nth-of-type(even) {
					border-left: convert-rem(2px) solid $grey-0;
				}

				&:nth-of-type(odd) {
					border-right: convert-rem(2px) solid $grey-0;
				}

				a {
					overflow-wrap: break-word;
					text-align: center;

					span {
						white-space: pre-wrap;
					}

					&:before {
						display: block;
						margin-bottom: $space-xs;
						font-size: $text-lg;
					}
				}
			}
		}

		ul:last-of-type {
			li {
				width: 100%;

				a {
					text-align: center;
					font-size: $text-md;

					&:before {
						margin-right: $space-sm;
					}
				}
			}
		}

		>a {
			background-color: $grey-0;
		}
	}

	.nav--horizontal.nav--collapsed {
		justify-content: center;

		ul {
			max-height: 0;
			width: 0;
			overflow: hidden;
		}

		ul:nth-of-type(2) {
			@include heightScaleIn($quick-animation-time);
		}
	}


	//Vertical mobile responsive
	.nav--vertical {
		position: absolute;
	}

	.nav--vertical.nav--expanded {
		height: 100%;
		width: 200px;
		border-radius: 0;
		margin: 0;
		@include slideInLeft($animation-time);

		>ul {
			>li {
				>.nav__sublinks {
					max-height: unset;
					max-width: 0;
					height: 100vh;
					top: 0;
					min-width: 0;

					&--visible {
						width: 100%;
						max-width: 300000px;
						@include slideInLeft($animation-time);
					}
				}
			}
		}
	}

	.nav--vertical.nav--collapsed {
		@include slideOutLeft($animation-time);
		background-color: transparent;
		box-shadow: none;

		ul {
			max-height: 0;
			width: 0;
			overflow: hidden;
			color: white;
			font-size: 20px;
		}

		>a {
			display: flex;
			align-items: center;
			justify-content: center;
			height: convert-rem(55px);
			width: convert-rem(55px);
			border-radius: 50%;
			background-color: $grey-8;
			@include slideInLeft($quick-animation-time);
		}
	}
}

// % protected region % [Change mobile navigation styles here] end

// % protected region % [Add additional navigation styles here] on begin
.navigation-link {
	&.collapsed {
		height: 40px;
	}
	height: fit-content;
	overflow-y: hidden;
}
// % protected region % [Add additional navigation styles here] end
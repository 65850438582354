﻿.audio-controls {
  display: flex;
  
  button {
    background: none;
    border: none;
    height: 100%;
    
    &:hover {
      cursor: pointer;
    }
    
    &:focus {
      outline: none;
    }
  }

  input[type=range] {
    height: 5px;
    -webkit-appearance: none;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
    background: #3b7677;
    transition: background 0.2s ease;
    cursor: pointer;
  }
}

.audio-controls .prev svg,
.audio-controls .next svg {
  width: 15px;
  height: 15px;
}

.audio-controls .play svg,
.audio-controls .pause svg {
  height: 20px;
  width: 20px;
}

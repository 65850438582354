/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
@import 'shared/harmony.scss';
// % protected region % [Add additional imports here] off begin
// % protected region % [Add additional imports here] end

.admin {
	@import 'admin/harmony.scss';
	@import 'admin/import-custom.scss';

	.graphiql-content-container {
		padding: 0;
		height: 100%;
	}

	/* % protected region % [Add extra admin scss here] off begin */
	/* % protected region % [Add extra admin scss here] end */
}

.frontend {
	// % protected region % [If you do not want to include any harmony in the front-end, you can remove the imports below and the scss files] off begin
	@import 'frontend/harmony.scss';
	//styles specific to bot only and not related to harmony
	@import 'frontend/custom-bot-imports.scss';
	// % protected region % [If you do not want to include any harmony in the front-end, you can remove the imports below and the scss files] end

	.layout__horizontal {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}

	.layout__vertical {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}

	.style-guide {
		padding: 2vw;
		margin-left: 3vw;
	}

	// % protected region % [Add additional front-end style content here] off begin
	// % protected region % [Add additional front-end style content here] end
}

// % protected region % [Customise react-contextify here] off begin
/* -------- CONTEXT MENU -------- */
//currently sitting outside of all body-content - style is super generic
@import 'admin/react-contextify.scss';
// % protected region % [Customise react-contextify here] end

/* % protected region % [Add extra scss here] on begin */
.modal-content.slideout-panel-right-lrg {
	top: 0;
	max-height: calc(100vh - 157.5px);
	height: calc(100vh - 140px);
	width: 80vw;
	min-width: 40vw;
	background-color: rgb(30, 30, 30);
	animation: slide-form-lrg 0.5s forwards;
	border-radius: 0;
	overflow-y: auto;
	overflow-x: hidden;
	margin: unset;
	right: 0;

	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgb(238, 200, 69);
		border-radius: 6px;
		border: 0px solid rgb(238, 200, 69);
	}

	.input-group {
		margin-bottom: 0;
		margin-top: 0;
	}

	.double-input {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.input-group {
			width: 100%;

			&:first-child {
				margin-right: 1rem;
			}

			&:last-child {
				margin-left: 1rem;
			}
		}
	}

	.input-group {
		input {
			background-color: rgb(25, 25, 25);
		}
	}

	.form-controls {
		position: fixed;
		height: 87.5px;
		max-height: 87.5px;
		bottom: 70px;
		background-color: rgb(35, 35, 35);
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		padding: 1rem;
		width: 80vw;
		min-width: 550px;
		right: 0;
		animation: slide-controls 0.5s forwards;

		button {
			margin-bottom: auto;
			margin-top: auto;

			&:first-child {
				margin-left: 0;
			}
		}

		#cancel {
			margin-left: auto;
		}

		#submit {
			margin-left: 1rem;
		}

		@media (max-width: 1440px) {
			#submit {
				margin-left: 0.5rem;
			}
		}
	}
}

.modal-content.slideout-panel-right {
	top: 0;
	max-height: calc(100vh - 157.5px);
	height: calc(100vh - 140px);
	width: 100%;
	max-width: 550px;
	margin-left: auto;
	background-color: rgb(30, 30, 30);
	animation: slide-form 0.5s forwards;
	border-radius: 0;
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;

	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgb(238, 200, 69);
		border-radius: 6px;
		border: 0px solid rgb(238, 200, 69);
	}

	.input-group {
		margin-bottom: 0;
		margin-top: 0;
	}

	.double-input {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.input-group {
			width: 100%;

			&:first-child {
				margin-right: 1rem;
			}

			&:last-child {
				margin-left: 1rem;
			}
		}
	}

	.input-group {
		input {
			background-color: rgb(25, 25, 25);
		}
	}

	.form-controls {
		position: fixed;
		height: 87.5px;
		max-height: 87.5px;
		min-width: 550px;
		bottom: 70px;
		background-color: rgb(35, 35, 35);
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		padding: 1rem;
		right: 0;
		animation: slide-controls 0.5s forwards;


		button {
			margin-bottom: auto;
			margin-top: auto;

			&:first-child {
				margin-left: 0;
			}
		}

		#cancel {
			margin-left: auto;
		}

		#submit {
			margin-left: 1rem;
		}

		@media (max-width: 1440px) {
			#submit {
				margin-left: 0.5rem;
			}
		}
	}
}

@keyframes slide-form {
	0% {
		right: -100%
	}

	100% {
		right: 0
	}
}

@keyframes slide-form-lrg {
	0% {
		right: -100%
	}

	100% {
		right: -20%
	}
}

@keyframes slide-controls {
	0% {
		right: -100%
	}

	100% {
		right: 0
	}
}

.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: black;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked+.slider {
	background-color: rgb(238, 200, 69);
}

input:focus+.slider {
	box-shadow: 0 0 1px rgb(238, 200, 69);
}

input:checked+.slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

.react-contexify.frontend {
	z-index: 3;
}

.track-wrapper-clone {
	p {
		font-size: 0.82rem;
	}

	img {
		width: 35px;
		height: 35px;
		user-select: none;
		border-radius: 4px;
		margin: auto 0.5rem auto 0;
	}

	.select-count {
		background-color: rgb(238, 200, 69);
		position: absolute;
		top: 2px;
		left: 35px;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		font-size: 0.82rem;
		height: 28px;
		width: 28px;
		text-align: center;
	}
}

/* % protected region % [Add extra scss here] end */

﻿.existing-track-panel {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow-x: auto;
	overflow-y: hidden;

	&::-webkit-scrollbar-thumb {
		background-color: $color-primary;
		border-radius: 6px;
		border: 0px solid $color-primary;
	}

	.existing-track-info {
		font-size: $text-md;
		margin-top: 1rem;
		color: $white;
	}

	.existing-track-warning {
		color: $grey-1;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	.existing-track-header {
		padding: 1rem;
		border-radius: $radius $radius 0 0;
		width: 100%;
		background: $grey-8;
		display: flex;
		flex-direction: row;
		font-size: $text-md;

		.f1 {
			flex: 1 1;
		}

		.f21 {
			flex: 2 1;
		}

		.f2 {
			flex: 2;
		}
	}

	.existing-track-container {
		padding: 1rem;
		display: flex;
		border-radius: $radius;
		overflow-y: scroll;
		flex: 1;
		flex-direction: column;
		width: 100%;
		background: $grey-8;
		height: calc(100vh - 395px);

		&.no-top-border {
			border-radius: 0 0 $radius $radius;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $color-primary;
			border-radius: 6px;
			border: 0px solid $color-primary;
		}

		.existing-track-row {
			display: flex;

			&.py-2 {
				padding-top: 5px;
				padding-bottom: 5px;
			}

			&.border {
				padding-bottom: 10px;
				border-bottom: thin outset grey;
			}

			div:not(.input-group__dropdown, .dropdown, .menu, .item) {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.track-title {
				color: $white;
				flex: 2;
			}

			.track-error {
				color: $white;
				flex: 3;
				font-size: smaller;
			}

			.track-discoTrackId,
			.track-album,
			.track-artist {
				flex: 1;
				color: $grey-1;
			}

			.line-number {
				color: $white;
				flex: 1;
			}

			.line-info {
				flex: 6;
				color: $white;
			}

			.import-track-tile {
				flex: 1;
			}

			.spotify-track-selection {
				margin-left: 0;
				flex: 2;

				div.dropdown {
					min-width: 20vw;

					.divider.text {
						max-width: 100%;
					}
				}
			}
		}
	}
}